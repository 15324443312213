/* courier-prime-regular - latin */
@font-face {
    font-family: 'Courier Prime';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('./courier-prime-v2-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('./courier-prime-v2-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
