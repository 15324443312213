/* oswald-regular - latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        /* IE6-IE8 */ url('./oswald-v36-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('./oswald-v36-latin-regular.woff');
}
/* oswald-300 - latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 300;
    src: local(''),
        /* IE6-IE8 */ url('./oswald-v36-latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */ url('./oswald-v36-latin-300.woff')
            format('woff');
}
